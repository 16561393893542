import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import AOS from "aos";

const ServiceDetail = () => {
  const { id } = useParams(); // Get the id from the route
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/v1/topics/2/page/1/count/10`;

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    const fetchServiceDetail = async () => {
      try {
        const response = await axios.get(API_URL);

        // Find the service with the matching id
        const serviceData = response.data.topics.find((item) => item.id === parseInt(id));

        if (serviceData) {
          // Extract <h4> and clean details
          const { subtitle, cleanDetails } = extractSubtitleAndClean(serviceData.details);
          setService({ ...serviceData, subtitle, details: cleanDetails });
        } else {
          setError("Service not found");
        }
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetail();
  }, [id, API_URL]);

  // Function to extract <h4> and remove it from details
  const extractSubtitleAndClean = (details) => {
    const h4Regex = /<h4[^>]*>(.*?)<\/h4>/;
    const match = details.match(h4Regex);

    // Extract subtitle if <h4> exists, otherwise set as null
    const subtitle = match ? match[1] : "No subtitle available";

    // Remove the <h4> tag and its content from the details
    const cleanDetails = details.replace(h4Regex, "").trim();

    return { subtitle, cleanDetails };
  };

  if (loading) {
    return   <section className="inner-banner-section skeleton-container">
    <div className="container">
      <div className="row">
        <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
          <div className="banner-caption">
            <div>
              <h5 className="text-banner-badge hero skeleton"> </h5>
              <h1 className="main-heading hero mb-0 skeleton"> </h1>
            </div>
            <p className="pera hero skeleton"> </p>
            <div className="d-flex gap-3 justify-content-between">
              <div className="btn-skeleton hero skeleton"></div>
              <div className="hero-btn-container d-flex gap-1">
                <span className="play-skeleton hero skeleton"></span>
                <p className="skeleton hero btn-skeleton"></p>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
          <div className="banner-img">
            <div className="video hero skeleton"></div>
          </div>
        </div>
      </div>
    </div>
  </section>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      {/* ---ServiceDetailPage--start----  */}
      <section className="service-detail sec_padding gradient-one">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="box-service-details pt-0 pt-md-4 mt-0" data-aos="zoom-in">
                <h5 className="Presents">
                  <span className="stylish">Michele </span>Presents
                </h5>
                {/* Dynamically Render Title and Subtitle */}
                <h3 className="sec_heading text-center">{service.title}</h3>
                <h4 className="sec_heading-stylis text-center">{service.subtitle}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----Section-second-service-detail---- */}
      <section className="sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6" data-aos="zoom-in-right">
              {/* Dynamic Image */}
              <div className="service-detail-img">
                <img src={service.photo_file} alt={service.title} />
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6" data-aos="zoom-in-left">
              {/* Dynamic Details */}
              <h2 className="sec_heading pt-2 pt-md-0">{service.title}</h2>
              <p
                className="pera"
                dangerouslySetInnerHTML={{ __html: service.details || "" }}
              />
              <Link to="/contact" className="btn theme_btn mt-3">
                Get in Touch
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* ----Subscribe-section---- */}
      <section className="subscribe sec_padding">
        <div className="container">
          <div className="row" data-aos="zoom-in">
            <div className="col-12 mb-md-4 mb-3">
              <h6 className="sub_heading text-center">Subscribe</h6>
              <h2 className="sec_heading-stylish text-center">
                Take the First Step
              </h2>
              <p className="pera text-center">
                Ready to embark on a transformative journey towards a healthier,
                happier you? Book a call today to discover the metamorphosis awaiting
                you with Michele’s expertise.
              </p>
              <form className="subscribe-box">
                <input
                  type="email"
                  className="input theme_input"
                  placeholder="Email"
                />
                <input
                  type="submit"
                  className="btn theme_btn"
                  value="BOOK A DISCOVERY CALL"
                />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetail;
