import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";

const ShopDetailPage = () => {
  const { productId } = useParams(); // Get the product ID from the URL
  const [productData, setProductData] = useState(null); // Store product data
  const [allProducts, setAllProducts] = useState([]); // Store all products data
  const [loading, setLoading] = useState(true); // Loading state
  const [quantity, setQuantity] = useState(1); // Quantity state

  useEffect(() => {
    AOS.init({ duration: 1200 });

    // Fetch the product details by product ID
    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/topic/${productId}`)
      .then((res) => res.json())
      .then((data) => {
        setProductData(data.topic[0]); // Assuming 'topic' contains the product details
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setLoading(false);
      });

    // Fetch all products (or a subset for related products)
    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/topics/8/page/1/count/100`) // Adjust the endpoint if necessary
      .then((res) => res.json())
      .then((data) => {
        setAllProducts(data.topics); // Assuming 'topics' contains all products
      })
      .catch((error) => {
        console.error("Error fetching all products:", error);
      });
  }, [productId]);

  const handleQuantityChange = (type) => {
    setQuantity((prev) => (type === "increase" ? prev + 1 : Math.max(prev - 1, 1)));
  };

  const calculateTotalPrice = () => {
    return productData ? parseFloat(productData.fields[0].value.replace('$', '')) * quantity : 0;
  };

  // If the product data is not yet loaded, show loading state
   if (loading) {
    return (
      <p>
     <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge hero skeleton"> </h5>
                                <h1 className="main-heading hero mb-0 skeleton"> </h1>
                            </div>
                            <p className="pera hero skeleton">
                                
                            </p>
                            <div className="d-flex gap-3 justify-content-between">
                            <div  className="btn-skeleton hero skeleton"></div>
                            <div className="hero-btn-container d-flex gap-1">
                                <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero  skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
     </section>
  </p>
    );
  }  

  // Ensure the productData is available before rendering the product details
  if (!productData) {
    return <p>Product not found.</p>;
  }

  // Filter out the current product from the allProducts list for related products
  const relatedProducts = allProducts.filter((product) => product.id !== productData.id);

  return (
    <>
      <Header />
      {/* Breadcrumb Section */}
      <section className="breadcrumb" data-aos="fade-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li><Link to="/">Home</Link></li>
                <li><i className="fa fa-angle-right"></i></li>
                <li><Link to="/shop">Shop</Link></li>
                <li><i className="fa fa-angle-right"></i></li>
                <li><Link to={`/shop-detail-page/${productData.id}`} className="active">{productData.title}</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Product Detail Section */}
      <section className="product-detail-container sec_padding">
        <div className="container">
          <div className="row">
            {/* Product Image and Sub-images */}
            <div className="col-sm-6">
              <div className="product-img-lg" data-aos="zoom-in">
                <img src={productData.photo_file || "assets/images/default-product.png"} alt={productData.title} />
              </div>
              <ul className="product-sub-imgs" data-aos="zoom-in">
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <li key={index} className="img-sub">
                    <img
                      src={productData.photo_file || "assets/images/default-product.png"}
                      alt={`Sub-image ${index + 1}`}
                    />
                  </li>
                ))}
              </ul>
            </div>

            {/* Product Details */}
            <div className="col-sm-6 mt-3 mt-sm-0" data-aos="zoom-in-left">
              <div className="product-details">
                <div className="d-flex justify-content-between flex-wrap">
                  <h4 className="product-category">{productData.Joined_categories[0]?.title}</h4>
                  <div className="product-rating">
                    {Array(5)
                      .fill()
                      .map((_, i) => (
                        <i key={i} className="fa-solid fa-star"></i>
                      ))}
                    <span className="rating-number">(540)</span>
                  </div>
                </div>
                <h2 className="prodcut-name">{productData.title}</h2>

                <div className="row">

                    <div class="col-sm-6">
                      {/* Quantity and Total Price */}
                      <div className="product-count">
                        <span className="product-minus" onClick={() => handleQuantityChange("decrease")}>
                          <i className="fa fa-minus"></i>
                        </span>
                        <input
                          type="number"
                          name="product-count"
                          value={quantity}
                          className="product-count"
                          readOnly
                        />
                        <span className="product-plus" onClick={() => handleQuantityChange("increase")}>
                          <i className="fa fa-plus"></i>
                        </span>
                      </div>

                      {/* Total Price */}
                      <p className="product-prize">${calculateTotalPrice().toFixed(2)}</p>

                      <Link to="#" className="btn theme_btn">Shop Now</Link>
                    </div>
                    <div class="col-sm-6">
                      <div class="video-thumbnail">
                        <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
                      </div>
                    </div>
                </div>
              </div>
            </div>

            {/* Product Description */}
            <div className="col-12 mt-3 mt-md-5" data-aos="zoom-in-up">
              <h2 className="sec_heading">Product Description</h2>
              <p
                className="pera product-desc"
                dangerouslySetInnerHTML={{ __html: productData.details }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      <hr />

      {/* Related Products Section */}
      <section className="related-products sec_padding">
        <div className="container">
          <div className="row g-1 g-sm-4">
            <div className="col-12" data-aos="fade-right">
              <h2 className="sec_heading">Related Products</h2>
            </div>

            {/* Check if related products are available */}
            {relatedProducts.length > 0 ? (
              relatedProducts.slice(0, 4).map((product) => (
                <div key={product.id} className="col-md-3 col-6" data-aos="zoom-in-up">
                  <Link to={`/shop-detail-page/${product.id}`} className="product-box">
                    <div className="product-img">
                      <img
                        src={product.photo_file || "assets/images/default-product.png"}
                        alt={product.title}
                      />
                    </div>
                    <div className="product-content">
                      <h2 className="product-title">{product.title}</h2>
                      <p className="product-rate">{product.fields?.[0]?.value || "$0.00"}</p>
                      <button className="shop-btn btn theme_btn">
                        Shop <span className="d-none d-sm-inline-block">Now</span>
                      </button>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>No related products available.</p>
            )}
          </div>
        </div>
      </section>
      
      <Footer />
    </>
  );
};

export default ShopDetailPage;
