import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Footer from "../components/Footer";
import BlogSidebar from "../components/BlogSidebar";
import MyPhilosophy from "../components/MyPhilosophy";
import BlogComponent from "../components/BlogComponent";
import AOS from 'aos';

const BlogPage = () => {
    AOS.init(
      {
          duration: 1200
        }
  ); // Initialize AOS animations
  
  return (
    <>
      <Header />

      {/* Breadcrumb Section */}
      <section className="breadcrumb" data-aos="fade-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" className="active">
                    Blogs
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* MyPhilosophy Section */}
      <MyPhilosophy />

      {/* Blog List Section */}
      <section className="blog-list sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4" data-aos="fade-right">
              <h6 className="sub_heading">Blogs</h6>
              <h2 className="sec_heading-stylish">Together We Will</h2>
            </div>
            <div className="col-md-9">
              <div className="row">
                <BlogComponent />
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BlogPage;
