import './setJQuery';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import BlogPage from "./pages/BlogPage";
import ShopPage from "./pages/ShopPage";
import ContactPage from "./pages/ContactPage";
import BlogDetailPage from "./pages/BlogDetailPage";
import ShopDetailPage from "./pages/ShopDetailPage";
import PodcastDetailPage from "./pages/PodcastDetailPage";
import PodcastPage from "./pages/PodcastPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";



import Comment from './components/Comment';
import ServiceDetailPage from './pages/ServiceDetailPage';
import Error404 from './pages/404';
import Welcome from './pages/Welcome';
import UserProfile from './pages/UserProfile';
// import './assets/css/owl.carousel.min.css';
// import './assets/css/bootstrap.min.css';

import './assets/css/stellarnav.css';
import './assets/css/style.css';
import './assets/css/skeleton.css';
import './assets/css/responsive.css';

const App = () => {
    return (
        <Router>
          
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Shape-Shifting-Mastery" element={<Welcome />} />

                <Route path="/about" element={<AboutPage />} />
                <Route path="/service" element={<ServicePage />} />
                <Route path="/service-detail/:id" element={<ServiceDetailPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog-detail-page/:id" element={<BlogDetailPage />} />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/shop-detail-page/:productId" element={<ShopDetailPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/podcast" element={<PodcastPage />} />
                <Route path="/podcast-detail-page" element={<PodcastDetailPage />} />
                <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
                <Route path="/comment" element={<Comment />} />
                <Route path="/404" element={<Error404 />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/UserProfile" element={<UserProfile />} />
                </Routes>
            
        </Router>
    );
};

export default App;
