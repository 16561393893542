import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import axiosInstance from "../Auth/axiosInstance"; // Import your Axios instance

const RegisterPage = () => {
  
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    rememberMe: false, // State for Remember Me checkbox
  });
  const navigate = useNavigate();

  AOS.init({ duration: 1200 });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      await axiosInstance.post("/api/register", {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.confirmPassword,
      });
      alert("Registration successful! Please login.");
      navigate("/login"); // Navigate to login after registration
    } catch (error) {
      console.error("Registration error:", error.response?.data?.message || error.message);
      alert("Registration failed");
    }
  };

  return (
    <div className="form_container">
      <div className="container-fluid">
        <div className="row d-flex row_form justify-content-center align-items-center flex-wrap-reverse">
          <div className="col-lg-6 form_bg p-0 m-0" data-aos="zoom-in">
            <div className="form_main">
              <div className="form">
                <div className="row heading text-center col-12">
                  <div className="logo-auth">
                    <img src="/logo.png" alt="Logo" />
                  </div>
                  <h2>Create an Account</h2>
                  <p>Join us and explore the possibilities</p>
                </div>

                <form id="form_register" onSubmit={handleRegister}>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="firstName">First Name</label>
                    </div>
                    <div className="col-12 form_input">
                      <input
                        type="text"
                        placeholder="Enter Your First Name"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="lastName">Last Name</label>
                    </div>
                    <div className="col-12 form_input">
                      <input
                        type="text"
                        placeholder="Enter Your Last Name"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="col-12 form_input">
                      <input
                        type="email"
                        placeholder="Enter Your Email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="password">Password</label>
                    </div>
                    <div className="col-12 form_input">
                      <input
                        type="password"
                        placeholder="Enter Your Password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                    </div>
                    <div className="col-12 form_input">
                      <input
                        type="password"
                        placeholder="Confirm Your Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <input
                        className="btn w-100 theme_btn"
                        value="Register"
                        type="submit"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <span className="subscription-info">
                        Already have an account?
                        <b style={{ paddingLeft: "5px" }}>
                          <Link
                           to={'/login'}
                            className="subscription-link"
                            style={{ cursor: "pointer" }}
                          >
                            Login Now
                          </Link>
                        </b>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block p-0 col-md-6 form_img_container">
            <div className="form-img" data-aos="zoom-in">
              <Link to={``}>
                <img loading="lazy" src="assets/images/login-poster.png" alt="form_img" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
