import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import AOS from 'aos';

const Philosophy = () => {
    const [philosophyData, setPhilosophyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const BASE_URL = process.env.REACT_APP_APP_URL  ;
    const API_URL = `${BASE_URL}/api/v1/topic/168`; // API URL for Philosophy

    useEffect(() => {
        AOS.init(
            {
                duration: 1200
              }
        ); // Initialize AOS animations
        const fetchPhilosophyData = async () => {
            try {
                const response = await axios.get(API_URL);
                setPhilosophyData(response.data);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchPhilosophyData();
    }, [API_URL]); // Add API_URL as a dependency

    if (loading) {
        return (
         
            <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge hero skeleton"> </h5>
                                <h1 className="main-heading hero mb-0 skeleton"> </h1>
                            </div>
                            <div className="pera hero skeleton"> {/* Change from <p> to <div> */}
                                {/* Skeleton content here */}
                            </div>
                            <div className="d-flex gap-3 justify-content-between">
                                <div className="btn-skeleton hero skeleton"></div>
                                <div className="hero-btn-container d-flex gap-1">
                                    <span className="play-skeleton hero skeleton"></span> 
                                    <p className="skeleton hero btn-skeleton"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        );
      }
    if (error) {
        return (
            <section className="sec_padding our-philosophy">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="sec_padding our-philosophy">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-md-4 mb-3 " data-aos="fade-right">
                        <h6 className="sub_heading text-center">Our Philosophy</h6>
                        <h2 className="sec_heading-stylish text-center">Together We Will    </h2>
                    </div>
                    <div className="col-md-6" data-aos="zoom-in-right">
                        <div className="pera" dangerouslySetInnerHTML={{ __html: philosophyData?.topic[0]?.details || '' }} />
                    </div>
                    <div className="col-md-6" data-aos="zoom-in-left">
                        <div className="right-img">
                            <img src={philosophyData?.topic[0]?.photo_file } alt="What We Do" />
                        </div>
                    </div>
                    <div className="col-12 text-center mt-3" data-aos="zoom-in-up">
                        <Link to="https://masterhabits.com/" className="btn theme_btn mt-3">Take a quiz now</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Philosophy;
