import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";

const ShopPage = () => {
  const [categories, setCategories] = useState([]); // Categories for filter
  const [uniqueCategories, setUniqueCategories] = useState([]); // Unique categories
  const [products, setProducts] = useState([]); // List of products
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered list based on categories & search
  const [selectedCategories, setSelectedCategories] = useState([]); // Selected categories for filtering
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [noResultsMessage, setNoResultsMessage] = useState(""); // No results message
  const [loading, setLoading] = useState(false); // Loading state

  const baseUrl = process.env.REACT_APP_APP_URL;
  const productsPerPage = 6; // Set to 6 products per page

  // Fetch categories and create a unique list
  useEffect(() => {
    AOS.init({ duration: 1200 });
    fetch(`${baseUrl}/api/v1/topics/8/page/1/count/100`)
      .then((response) => response.json())
      .then((data) => {
        if (data?.topics) {
          setProducts(data.topics); // Set products from the API response
          setFilteredProducts(data.topics); // Initially set all products as filtered
          
          // Extract unique categories
          const allCategories = data.topics.flatMap((product) => product.Joined_categories);
          const uniqueCategoryTitles = [
            ...new Set(allCategories.map((cat) => cat.title)),
          ];
          setUniqueCategories(uniqueCategoryTitles); // Set unique categories
        }
      })
      .catch((error) => console.error("Error fetching categories and products:", error));
  }, [baseUrl]);

  // Update filtered products based on selected categories and search query
  useEffect(() => {
    let updatedProducts = products;

    // Filter products by selected categories
    if (selectedCategories.length > 0) {
      updatedProducts = updatedProducts.filter((product) =>
        product.Joined_categories?.some((cat) => selectedCategories.includes(cat.title))
      );
    }

    // Filter by search query
    if (searchQuery) {
      updatedProducts = updatedProducts.filter((product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Set filtered products and no results message
    setFilteredProducts(updatedProducts);
    setNoResultsMessage(updatedProducts.length ? "" : "No products found for the selected filters.");
  }, [selectedCategories, searchQuery, products]);

  // Handle category filter changes
  const handleCategoryChange = (categoryTitle) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryTitle)
        ? prev.filter((title) => title !== categoryTitle)
        : [...prev, categoryTitle]
    );
  };

  // Handle search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Header />

      {/* Breadcrumb Section */}
      <section className="breadcrumb" data-aos="fade-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/"><i className="fa fa-angle-right"></i></Link></li>
                <li><Link to="/" className="active">Shop</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Product Listing Section */}
      <section className="product-listing sec_padding">
        <div className="container">
          <div className="row">
            {/* Sidebar: Category Filter */}
            <div className="col-md-3 border-end mb-3">
              <div className="row shop-sidebar" data-aos="fade-right">
                <div className="col-12 mb-4">
                  <h2 className="sec_heading pb-2 mb-0 border-bottom">
                    Category <span>({uniqueCategories.length})</span>
                  </h2>
                </div>
                <div className="col-12">
                  <form>
                    <ul className="product-category">
                      {uniqueCategories.length > 0 ? (
                        uniqueCategories.map((categoryTitle) => (
                          <li key={categoryTitle} className="category-item">
                            <input
                              type="checkbox"
                              id={`category-${categoryTitle}`}
                              onChange={() => handleCategoryChange(categoryTitle)}
                            />
                            <label htmlFor={`category-${categoryTitle}`}>
                              {categoryTitle}
                            </label>
                          </li>
                        ))
                      ) : (
                        <li>No categories available</li>
                      )}
                    </ul>
                  </form>
                </div>
              </div>
            </div>

            {/* Product List */}
            <div className="col-md-9">
              <div className="d-flex pb-2 mb-4 gap-2 border-bottom justify-content-between align-items-center flex-wrap">
                <h2 className="sec_heading mb-0" data-aos="fade-right">
                  Product List <span>({filteredProducts.length})</span>
                </h2>

                {/* Search Bar */}
                <div className="searchbar" data-aos="fade-left">
                  <input
                    type="search"
                    className="search-input"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <div className="icon-submit"><i className="fas fa-search"></i></div>
                </div>
              </div>

              {/* Product Display */}
              <div className="row g-1 g-sm-4" data-aos="fade-up">
                {loading ? (
                  <p>Loading...</p>
                ) : filteredProducts.length ? (
                  filteredProducts.map((product) => (
                    <div key={product.id} className="col-md-4 col-6">
                      <Link to={`/shop-detail-page/${product.id}`} className="product-box">
                        <div className="product-img">
                          <img src={product.photo_file} alt={product.title} />
                        </div>
                        <div className="product-content">
                          <h2 className="product-title">{product.title}</h2>
                          <div className="product-rating">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                          </div>
                          <div className="product-price">
                            {product.fields[0].value} {/* Assuming the price is always in the first field */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>{noResultsMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ShopPage;
