import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import axios from 'axios';

const Contact = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    // Fetch API data
    const fetchContactData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_URL}/api/v1/topic/196`);
        setContactData(response.data.topic[0]);
      } catch (error) {
        console.error("Error fetching contact data:", error);
      }
      finally {
        setLoading(false);
    }
    };
    
    fetchContactData();
  }, []);

  if (loading) {
    return (
      <p>
     <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge hero skeleton"> </h5>
                                <h1 className="main-heading hero mb-0 skeleton"> </h1>
                            </div>
                            <p className="pera hero skeleton">
                                
                            </p>
                            <div className="d-flex gap-3 justify-content-between">
                            <div  className="btn-skeleton hero skeleton"></div>
                            <div className="hero-btn-container d-flex gap-1">
                                <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero  skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
     </section>
  </p>
    );
  }
  const hours = contactData.fields.find(field => field.title === "Hours")?.value;
  const [mondayToFriday, weekendHours] = hours ? hours.split("\r\n") : [];

  return (
    <>
      <section className="contact-contaier sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6" data-aos="zoom-in-right">
              <div className="content-contact">
                <h2 className="sec_heading">Contact Me</h2>
                <p className="pera">{contactData.fields.find(field => field.title === "Contact- Me")?.value}</p>
              </div>
            </div>
            <div className="col-md-6">
              <form className="contact-form" data-aos="zoom-in-left">
                <h2 className="sec_heading-stylish">Get in Touch</h2>
                <div className="fields">
                  <input
                    type="text"
                    className="theme_input"
                    name="name"
                    placeholder="Your Name"
                  />
                  <input
                    type="email"
                    className="theme_input"
                    name="email"
                    placeholder="Your Email"
                  />
                  <textarea
                    name="msg"
                    className="theme_input"
                    placeholder="Type Message here"
                    rows="5"
                    id="Msg"
                  ></textarea>
                  <input
                    type="submit"
                    className="btn theme_btn"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="contact-bg-center">
                <img
                  src="/assets/images/contact-plan.png"
                  className="img"
                  alt="Contact Background"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="we-are-here sec_padding">
        <div className="container">
          <div className="row">
            <div class="col-md-12">
             <h2 className="sec_heading" data-aos="fade-right">{contactData.title}</h2>
            </div>
            <div className="col-sm-6">
              
              <Link to="#" className="box-contact mt-3" data-aos="zoom-in-right">
                <div className="icon">
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Location</h5>
                  <p className="pera">{contactData.fields.find(field => field.title === "Location")?.value}</p>
                </div>
              </Link>
            </div>
            <div className="col-sm-6">
            <Link to="#" className="box-contact mt-3" data-aos="zoom-in-right">
                <div className="icon">
                  <i className="fa-solid fa-clock"></i>
                </div>
                <div className="content">
                  <h5 className="title-box">Hours</h5>
                  {mondayToFriday && <p className="pera">{mondayToFriday}</p>}
                  {weekendHours && <p className="pera">{weekendHours}</p>}
                </div>
              </Link>
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
