import React, { useState } from "react";

const Notifications = () => {

  return (
    <div className="card mb-2">
      <h5 className="card-header pb-1">Notification</h5>
      <div className="card-body">
        <span>
          Change to notification settings, the user will get the update
        </span>
      </div>
      <div className="table-responsive px-3">
        <table className="table table-striped border-top">
          <thead>
            <tr>
              <th className="text-nowrap">Message</th>
            </tr>
          </thead>
          <tbody>
            <td>Change to notification settings, the user will get the update
            </td>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Notifications;
