import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Podcast from "../components/Podcast";

const PodcastPage = () => {
    return (
        <>
        
            <Header />
            {/* ---bradrumb--start----  */}

            <section className="breadcrumb" data-aos="fade-right">

                <div className="container">

                    <div className="row">

                        <div className="col-12">

                            <ul className="items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">Podcast</Link>
                                </li>
                            </ul>
                        
                        </div>

                    </div>

                </div>

            </section>

            {/* ---bradrumb--end----  */}
            <Podcast />
            <Footer />
        </>
    );
};

export default PodcastPage;
