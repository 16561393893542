import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Footer from "../components/Footer";
import axios from "axios";
import AOS from 'aos';

const PodcastDetailPage = () => {
  const [ServiceData, setServiceData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const SERVICE_API = `${BASE_URL}/api/v1/topic/10`;

  useEffect(() => {
    AOS.init(
      {
          duration: 1200
        }
  ); // Initialize AOS animations
    // Fetch data from SERVICE_API
    axios
      .get(SERVICE_API)
      .then((response) => {
        setServiceData(response.data.topic[0]);
        setLoading(false); // set loading to false after data is fetched
      })
      .catch((error) => {
        setError("Error fetching service data");
        setLoading(false);
      });
  }, [SERVICE_API]);

  const extractListItems = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const listItems = doc.querySelectorAll("li");
    return Array.from(listItems).map((item) => item.textContent);
  };

  if (loading) {
    return (
      <p>
     <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge hero skeleton"> </h5>
                                <h1 className="main-heading hero mb-0 skeleton"> </h1>
                            </div>
                            <p className="pera hero skeleton">
                                
                            </p>
                            <div className="d-flex gap-3 justify-content-between">
                            <div  className="btn-skeleton hero skeleton"></div>
                            <div className="hero-btn-container d-flex gap-1">
                                <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero  skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
     </section>
  </p>
    );
  }
  if (error) return <p>{error}</p>;

  return (
    <>
      <Header />
      {/* ---bradrumb--start----  */}
      <section className="breadcrumb" data-aos="zoom-in-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" className="active">
                    Podcast
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" className="active">
                    Podcast Detail
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* ---bradrumb--end----  */}
      <section className="podcast-detail sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="zoom-in-up">
              <h2 className="sec_heading text-center">
                Transform Your Health: A 60-Day Metamorphosis with Michele Drake
              </h2>
              <p className="pera text-center">
                Are you ready to transform your health and reclaim your vitality? Join Michele Drake, a seasoned expert with over 35 years in alternative health and wellness, on a 60-day journey that will change your life. Discover a unique protocol that resets your body’s set point, ensuring sustainable weight loss and a renewed sense of well-being. Say goodbye to diets and hello to a healthier, happier you. Find out more about this transformative metamorphosis today!
              </p>
            </div>
            <div className="col-12" data-aos="zoom-in-up">
              <div className="detail-podcast-video">
                <video controls src="https://www.ssmcanada.com/wp-content/uploads/2024/06/WhatsApp-Video-2024-06-01-at-10.44.50-AM.mp4" />
              </div>
            </div>
            <div className="col-12 text-center" data-aos="zoom-in-up">
              <Link to="/contact" className="btn theme_btn">
                BOOK A DISCOVERY CALL
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Service Data Section */}
      {ServiceData && (
        <section className="sec_padding podcast-detail-content">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-md-5" data-aos="fade-right">
                <h2 className="sec_heading-stylish text-center">
                  {ServiceData.title}
                </h2>
                <span className="down-arrow">
                  <i className="fa-solid fa-arrow-down-long"></i>
                </span>
              </div>
              <div className="col-lg-5" data-aos="zoom-in-right">
                <div className="content-service">
                  <h1 className="main-heading mb-0">
                    {ServiceData.fields && ServiceData.fields[0]?.value}
                  </h1>
                  <p className="pera">
                    {ServiceData.details?.match(/<p>(.*?)<\/p>/)?.[1]}
                  </p>
                  <ul className="list-with-check">
                    {extractListItems(ServiceData.details).map((item, index) => (
                      <li key={index}>
                        <i className="fa fa-check"></i>
                        <span className="pera">{item}</span>
                      </li>
                    ))}
                  </ul>
                  <Link to="/" className="btn theme_btn">
                    Learn More
                  </Link>
                </div>
              </div>
              {/* Add the image here */}
              <div className="col-lg-7 mt-3 mt-lg-0 mt-3 mt-sm-4" data-aos="zoom-in-left">
                <div className="img-right top">
                  <img
                    src={ServiceData.photo_file}
                    alt={ServiceData.title}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center mt-3">
            <Link to="/contact" className="btn theme_btn">
              BOOK A DISCOVERY CALL
            </Link>
          </div>
        </section>
      )}

      <Footer />
    </>
  );
};

export default PodcastDetailPage;
