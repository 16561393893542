import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const Welcome = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL;
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v1/topic/216`
        );
        setData(response.data.topic[0]);
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, [BASE_URL]);

  if (loading) {
    return (
     
      <section className="inner-banner-section skeleton-container">
        <div className="container">
          <div className="row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <div>
                  <h5 className="text-banner-badge hero skeleton"> </h5>
                  <h1 className="main-heading hero mb-0 skeleton"> </h1>
                </div>
                <p className="pera hero skeleton"> </p>
                <div className="d-flex gap-3 justify-content-between">
                  <div className="btn-skeleton hero skeleton"></div>
                  <div className="hero-btn-container d-flex gap-1">
                    <span className="play-skeleton hero skeleton"></span>
                    <p className="skeleton hero btn-skeleton"></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <div className="video hero skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    );
  }

  if (error) {
    return <div>{error}</div>;
  }


  const detailOne = data.fields.find((field) => field.title === "Detail one")?.value;
  const imageOne = data.fields.find((field) => field.title === "Image one")?.value;
  const detailtwo = data.fields.find((field) => field.title === "Detail two")?.value;
  const SessionInfo = data.fields.find((field) => field.title === "Session Info")?.value;
  const imageTwo = data.fields.find((field) => field.title === "Image two")?.value;
  const listField = data.fields.find((field) => field.title === "List");
  const listItems = listField?.value.split("\r\n") || [];
  const detailthree = data.fields.find((field) => field.title === "Detail three")?.value || "";
  const detailThrees = detailthree.split("\r\n");
  const regards = data.fields.find((field) => field.title === "Regards")?.value || "";
  const regardsLines = regards.split("\r\n");
  const detailFour = data.fields.find((field) => field.title === "Detail four")?.value || "";
  const detailFourLines = detailFour.split("\r\n");
  







  return (
    <>
      <Header />

      {/* Breadcrumb */}
      <section className="breadcrumb pb-0" data-aos="fade-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/welcome" className="active">
                    Welcome
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Top Section */}
      <section className="welcome-top sec_padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="box-welcome-top pt-0 pt-md-4 mt-0"
                data-aos="zoom-in"
              >
                <h5 className="Presents text-center">
                  <span className="stylish">Michele </span>Presents
                </h5>
                <h3 className="title text-center">
                  {data.title.split(" ").map((word, index) => (
                    <span
                      key={index}
                      className={
                        word.toLowerCase() === "metamorphosis" ? "stylish" : ""
                      }
                    >
                      {word}{" "}
                    </span>
                  ))}
                </h3>
                {data.fields.find((field) => field.title === "Sub heading") && (
                  <h4 className="sub_heading text-center">
                    {
                      data.fields.find((field) => field.title === "Sub heading")
                        .value
                    }
                  </h4>
                )}

                <div class="hosted-container">

                  <div className="d-flex logo-container flex-column flex-wrap gap-1">

                    <h2 className="Presents text-center mb-1 mb-md-3">hosted by </h2>

                    <img src="/assets/images/host-logo.png" className="hosted-logo" alt=""/>

                  </div>

                  <div class="hosted-content d-flex flex-wrap gap-2">
                  <p className="pera text-center">251 Lakeshore Rd. E. Oakville, ON L6J 1H9 
                  </p>
                  
                  </div>
                  <div class="hosted-content d-flex flex-wrap gap-2">
                  <p className="pera text-center"><b>Date & Time:</b> Date is Saturday, December 14th at 10:30 am EST - 11:30 am EST </p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----Unique Program Section---- */}
      <section className="sec_padding unique-program pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="unique-program-content">
                <p className="pera text-center" data-aos="fade-up">
                  {detailOne}
                </p>
                <button
                  className="btn theme_btn"
                  data-aos="fade-up"
                  data-bs-toggle="modal"
                  data-bs-target="#registerModal"
                >
                  Register Now
                </button>

                <div className="img-unique-program" data-aos="fade-up">

                  <img src={imageOne ? `${BASE_URL}/uploads/topics/${imageOne}` : ""} alt="Mission-Slimpossible" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------------Information Session--------------  */}

      <section class="sec_padding session-information">
        <div class="container">
          <div class="row py-3 py-md-5">
            <div class="col-12" data-aos="fade-up">
              <p class="pera text-center">
                {detailtwo}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div className="d-flex justify-content-center" data-aos="fade-up">
                <div class="session-information-box">

                  <h2 className="sec_heading text-center">{SessionInfo}</h2>
                  <div class="row">
                    <div class="col-md-6">

                      <ul className="list-with-check pt-2">
                        {listItems.map((item, index) => (
                          <li key={index}>
                            <i className="fa fa-check"></i>
                            <span className="pera">{item}</span>
                          </li>
                        ))}
                      </ul>


                      <div>
                        {detailThrees.map((item, index) => (
                          <p key={index} className="pera">
                            {item}
                          </p>
                        ))}
                      </div>
                      <div className="about-box">
                        {regardsLines.map((line, index) => (
                          <p key={index} className="pera fw-bold">
                            {line}
                          </p>
                        ))}
                      </div>


                    </div>
                    <div class="col-md-6">
                      <div className="img-welcome-ready" data-aos="zoom-in-right">
                      {/*  eslint-disable-next-line */}
                        <img src={imageTwo ? `${BASE_URL}/uploads/topics/${imageTwo}` : ""} alt="Mission-Slimpossible-2 Image Not Found" loading="lazy" />

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>

      {/* -----section-bottom-about------  */}

      <section class="sec_padding bottom-section-welcome">
        <div class="container">
          <div class="row">
           
            <div class="col-xl-12">

            <div className="content-bottom-about" data-aos="fade-up">
    {detailFourLines.map((line, index) => {
      // Render the mission statement
      if (line.includes("Mission Slimpossible")) {
        return (
          <p key={index} className="sub_heading text-center">
            {line}
          </p>
        );
      }
      // Render the line with the name
      else if (line.includes("Here's to a life full of kindnesses and laughter")) {
        return (
          <p key={index} className="pera fw-bold">
            {line}
          </p>
        );
      }
      // Render the name (Michele)
      else if (line === "Michele") {
        return (
          <p key={index} className="pera fw-bold">
            {line}
          </p>
        );
      }
      // Render the website link
      else if (line === "www.masterhabits.com") {
        return (
          <Link key={index} href="https://www.masterhabits.com" target="_blank" rel="noopener noreferrer">
            {line}
          </Link>
        );
      }
      // Render the phone number
      else if (line === "1-778-668-(LOVE)5683") {
        return (
          <p key={index} className="pera">
            {line}
          </p>
        );
      }
      // Render any other lines as regular text
      else {
        return (
          <p key={index} className="pera">
            {line}
          </p>
        );
      }
    })}

    <button
                  className="btn theme_btn"
                  data-aos="fade-up"
                  data-bs-toggle="modal"
                  data-bs-target="#registerModal"
                >
                  Register Now
                </button>
  </div>

            </div>
          </div>
        </div>
      </section>


      <Footer />
      <div
            className="modal fade"
            id="registerModal"
            tabIndex="-1"
            aria-labelledby="registerModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <h2 class="sec_heading-stylish  mb-0">Register Now</h2>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
                </div>
                <div className="modal-body">
                {/* <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>Registration Successfull!</strong>.
                </div> */}
                <form className="welcome-register-form contact-form pt-0">
                    <div className="fields">
                    <div className="input-field">
                      <input
                          type="text"
                          className="theme_input form-control mb-2"
                          name="First name"
                          placeholder="First Name*"
                      />
                      <span className="required">This field is required.</span>
                    </div>
                    <div className="input-field">

                    <input
                        type="text"
                        className="theme_input form-control mb-2"
                        name="Last name"
                        placeholder="Last Name"
                    />
                    </div>
                    <div className="input-field">
                      
                    <input
                        type="tel"
                        className="theme_input form-control mb-2"
                        name="Number"
                        placeholder="Your Number"
                    />
                      </div>
                    <div className="input-field">

                    <input
                        type="email"
                        className="theme_input form-control mb-2"
                        name="email"
                        placeholder="Your Email*"
                    />
                    <span className="required">This field is required.</span>
                    </div>
                    <input
                        type="submit"
                        className="btn theme_btn w-100"
                        value="Register Now"
                    />
                    </div>
                    <div class="alert alert-success mt-2" role="alert">
                      You have successfully registered!
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>  
     
    
    </>
  );
};

export default Welcome;