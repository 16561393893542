import React, { useState, useEffect } from "react";
import axios from "axios";
import AOS from 'aos';

const WhyChooseUs = () => {
    const BASE_URL = process.env.REACT_APP_APP_URL;
    const VALUE_API_URL = `${BASE_URL}/api/v1/topic/186`; // API URL for "Why Choose Us" content
    const IMAGE_API_URL = `${BASE_URL}/api/v1/topic/photos/209`; // API URL for "Why Choose Us" photos

    const [valueData, setValueData] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        AOS.init({ duration: 1200 }); // Initialize AOS animations

        const fetchData = async () => {
            try {
                const contentResponse = await axios.get(VALUE_API_URL); // Fetch content data
                setValueData(contentResponse.data.topic[0]);
                
                const imagesResponse = await axios.get(IMAGE_API_URL); // Fetch images data
                setImageData(imagesResponse.data);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [VALUE_API_URL, IMAGE_API_URL]);

    if (loading) {
        return (
       
                <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                                <h5 className="text-banner-badge hero skeleton"> </h5>
                                <h1 className="main-heading hero mb-0 skeleton"> </h1>
                            </div>
                            <div className="pera hero skeleton"> {/* Change from <p> to <div> */}
                                {/* Skeleton content here */}
                            </div>
                            <div className="d-flex gap-3 justify-content-between">
                                <div className="btn-skeleton hero skeleton"></div>
                                <div className="hero-btn-container d-flex gap-1">
                                    <span className="play-skeleton hero skeleton"></span> 
                                    <p className="skeleton hero btn-skeleton"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         
        );
    }

    if (error) return <p>{error}</p>;

    // Extract content data from the fetched API response
    const contentOne = valueData?.fields?.find(field => field.title === "content-one")?.value;
    const contentTwo = valueData?.fields?.find(field => field.title === "content-two")?.value;
    const contentThree = valueData?.fields?.find(field => field.title === "content-three")?.value;
    const contentFour = valueData?.fields?.find(field => field.title === "content-four")?.value;

    // Extract image data from the fetched images API response
    const imgOne = imageData?.photos[0]?.url;
    const imgTwo = imageData?.photos[1]?.url;
    const imgThree = imageData?.photos[2]?.url;
    const mainImage =imageData?.topic_photo;

    return (
        <>
            <section className="sec_padding why-choose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg" data-aos="fade-right">
                            <div className="content-top pr-md-5">
                                <h6 className="sub_heading">Value</h6>
                                <h2 className="sec_heading-stylish">Why Choose Us</h2>
                                <p className="title-pera pt-2 pb-3">{contentOne || "Default content for content-one..."}</p>
                            </div>
                            <img src={mainImage} className="why-use-img" alt="why choose us" />
                        </div>
                        <div className="col-lg-3" data-aos="fade-up">
                            <div className="item-why-us one">
                                <div className="icon-why">
                                    <img src={imgOne || "assets/images/why-us-one.png"} alt=""/>
                                </div>
                                <p className="pera">{contentTwo || "Default content for content-two..."}</p>
                            </div>
                        </div>
                        <div className="col-lg" data-aos="fade-left">
                            <div className="item-why-us-right border-start border-theme">
                                <div className="item-why-us two border-bottom border-theme">
                                    <div className="icon-why">
                                        <img src={imgTwo || "assets/images/why-us-two.png"} alt=""/>
                                    </div>
                                    <p className="pera">{contentThree || "Default content for content-three..."}</p>
                                </div>
                                <div className="item-why-us three">
                                    <div className="icon-why">
                                        <img src={imgThree || "assets/images/why-us-three.png"} alt=""/>
                                    </div>
                                    <p className="pera">{contentFour || "Default content for content-four..."}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WhyChooseUs;
