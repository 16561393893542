import React from "react";
import { Link } from "react-router-dom";
import LightentheRoad from "./LightentheRoad";

const Podcast = () => {
    // Example data: Replace with dynamic data if needed
    const podcastItems = new Array(6).fill(<LightentheRoad />); // Array of 6 podcast items
    
    const totalPages = 4; // Example: Total number of pages for pagination

    return (
        <section className="podcast-items">
            {/* Render podcast items dynamically */}
            {podcastItems.map((item, index) => (
                <div className="podcast-item" key={index}>
                    {item}
                </div>
            ))}

            {/* Pagination section */}
            <div className="pagination">
                <Link to="#" aria-label="Previous page">
                    <i className="fa-solid icon disabled pre fa-arrow-left" />
                </Link>
                <ul className="pagination-items">
                    {/* Dynamically generate pagination items */}
                    {[...Array(totalPages).keys()].map((page) => (
                        <Link to="#" key={page}>
                            <li className={`item ${page === 0 ? "active" : ""}`}>{page + 1}</li>
                        </Link>
                    ))}
                </ul>
                <Link to="#" aria-label="Next page">
                    <i className="fa-solid icon next fa-arrow-right" />
                </Link>
            </div>
        </section>
    );
};

export default Podcast;
