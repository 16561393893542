import React, { useState } from "react";
import { Link } from "react-router-dom";

const CurrentPlan = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleCancelSubscription = () => {
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle the subscription cancellation logic here
    console.log("Subscription Cancelled");
    setShowCancelModal(false);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card mb-2">
          <h5 className="card-header">Current Plan</h5>
          <div className="card-body">
            <div className="row">
              {/* Left Column */}
              <div className="col-xl-6 order-1 order-xl-0">
                <div className="mb-1">
                  <h6 className="mb-1">Your Current Plan is Basic</h6>
                  <p>A simple start for everyone</p>
                </div>
                <div className="mb-1 pt-1">
                  <h6 className="mb-1">Active until Dec 09, 2021</h6>
                  <p>We will send you a notification upon subscription expiration</p>
                </div>
                <div className="mb-1 pt-1">
                  <h6 className="mb-1">
                    <span className="me-2">$199 Per Month</span>
                    <span className="badge bg-color-primary">Popular</span>
                  </h6>
                  <p>Standard plan for small to medium businesses</p>
                </div>
              </div>

              {/* Right Column */}
              <div className="col-xl-6 order-0 order-xl-0">
                <div className="alert alert-primary" role="alert">
                  <h5 className="alert-heading mb-2">We need your attention!</h5>
                  <span>Your plan requires update</span>
                </div>
                <div>
                   <span>If You want know about more your existing Plan </span> <Link to="#" className="link"> Click More</Link>
                </div>
              </div>

              {/* Buttons */}
              <div className="col-12 order-2 order-xl-0 d-flex gap-2">
                <button
                  className="btn theme_btn me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#upgradePlanModal"
                >
                  Upgrade Plan
                </button>
                <button
                  className="btn tp_btn cancel-subscription"
                  onClick={handleCancelSubscription}
                  data-bs-toggle="modal"
                  data-bs-target="#cancelSubscriptionModal"
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Bootstrap Modal for Upgrade Plan */}
        <div
          className="modal fade"
          id="upgradePlanModal"
          tabIndex="-1"
          aria-labelledby="upgradePlanModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="upgradePlanModalLabel">
                  Upgrade Your Plan
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="planType" className="form-label">
                      Select Plan
                    </label>
                    <select className="form-select" id="planType">
                      <option value="basic">Basic - $199/month</option>
                      <option value="standard">Standard - $299/month</option>
                      <option value="premium">Premium - $399/month</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Upgrade Now
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Bootstrap Modal for Cancel Subscription */}
        <div
          className="modal fade"
          id="cancelSubscriptionModal"
          tabIndex="-1"
          aria-labelledby="cancelSubscriptionModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="cancelSubscriptionModalLabel">
                  Confirm Subscription Cancellation
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleFormSubmit}>
                <div class="alert alert-danger" role="alert">
                Are you sure you want to cancel your subscription?
                </div>
                  <p></p>
                  <div className="mb-3">
                    <label htmlFor="reason" className="form-label">
                      Reason for Cancellation
                    </label>
                    <textarea
                      className=""
                      id="reason"
                      rows="3"
                      placeholder="Please let us know why you're canceling"
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="feedback" className="form-label">
                      Additional Feedback (Optional)
                    </label>
                    <textarea
                      className=""
                      id="feedback"
                      rows="3"
                      placeholder="Any suggestions for improvement?"
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-danger">
                      Confirm Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;
