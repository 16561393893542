import { useState } from "react";

const PasswordSecurity = () => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <div className="card mb-2">
      <h5 className="card-header profile-sec_heading">Security</h5>
      <div className="card-body">
        {/* Change Password */}
        <form id="formChangePassword" method="POST">
          <div className="row">
            {/* Old Password */}
            <div className="mb-3 col-12 col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="oldPassword">
                Old Password
              </label>
              <div className="input-group">
                <input
                  className="form-control theme_input"
                  type={passwordVisibility.oldPassword ? "text" : "password"}
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="********"
                />
                <span
                  className="icon-show-hide"
                  onClick={() => togglePasswordVisibility("oldPassword")}
                  style={{ cursor: "pointer" }}
                >
                  {passwordVisibility.oldPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>
            {/* New Password */}
            <div className="mb-3 col-12 col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="newPassword">
                New Password
              </label>
              <div className="input-group">
                <input
                  className="form-control theme_input"
                  type={passwordVisibility.newPassword ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  placeholder="********"
                />
                <span
                  className="icon-show-hide"
                  onClick={() => togglePasswordVisibility("newPassword")}
                  style={{ cursor: "pointer" }}
                >
                  {passwordVisibility.newPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>
            {/* Confirm Password */}
            <div className="mb-3 col-12 col-sm-6 form-password-toggle">
              <label className="form-label" htmlFor="confirmPassword">
                Confirm New Password
              </label>
              <div className="input-group">
                <input
                  className="form-control theme_input"
                  type={passwordVisibility.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="********"
                />
                <span
                  className="icon-show-hide"
                  onClick={() => togglePasswordVisibility("confirmPassword")}
                  style={{ cursor: "pointer" }}
                >
                  {passwordVisibility.confirmPassword ? (
                    <i className="fa-solid fa-eye-slash"></i>
                  ) : (
                    <i className="fa-solid fa-eye"></i>
                  )}
                </span>
              </div>
            </div>
            {/* Submit Button */}
            <div className="col-12 w-100">
              <button type="submit" className="btn theme_btn">
                Change Password
              </button>
            </div>
          </div>
        </form>
        {/* /Change Password */}
      </div>
    </div>
  );
};

export default PasswordSecurity;
