import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../Auth/axiosInstance"; // Import your Axios instance

const UserSidebar = () => {
  const [imagePreview, setImagePreview] = useState("/assets/images/Michele.png");
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  // Fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Get the token
        if (!token) {
          navigate("/login");
          return;
        }

        const response = await axiosInstance.get("api/user/", {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token in headers
          },
        });

        if (response.status === 200) {
          setUserDetails(response.data); // Set user details in state
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        navigate("/login"); // Redirect to login if the token is invalid
      }
    };

    fetchUserDetails();
  }, [navigate]);

  // Handle image upload preview
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get("api/logout", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        localStorage.removeItem("authToken"); // Clear token
        navigate("/login"); // Redirect to login page
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (!userDetails) {
    return <div>Loading user details...</div>; // Show loading state while fetching data
  }

  // Split name into firstName and lastName
  const [firstName, lastName] = userDetails.name.split(" ");

  return (
    <>
      <div className="card mb-2">
        <div className="card-body">
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="imageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <img
                    loading="lazy"
                    src={imagePreview}
                    height="100"
                    width="100"
                    alt="User avatar"
                  />
                </div>
              </div>
              <div className="user-info text-center">
                <h4 className="user-name">{`${firstName} ${lastName}`}</h4>
              </div>
            </div>
          </div>
          <div className="border-bottom py-2"></div>
          <div className="d-flex justify-content-between py-2">
            <h3 className="profile-sec_heading">Details</h3>
            <Link
              to="#"
              className=""
              data-bs-toggle="modal"
              data-bs-target="#editModal"
            >
              <i className="fa fa-edit"></i>
            </Link>
          </div>
          <div className="info-container">
            <ul className="list-unstyled">
              <li className="mb-1">
                <span className="fw-medium me-1">First Name:</span>
                <span>{firstName}</span>
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Last Name:</span>
                <span>{lastName}</span>
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Email:</span>
                {userDetails.email}
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Number:</span>
                {userDetails.phone || "Not provided"}
              </li>
              <li className="mb-1 pt-1">
                <span className="fw-medium me-1">Status:</span>
                <span className={`badge bg-${userDetails.is_active ? "success" : "danger"}`}>
                  {userDetails.is_active ? "Active" : "Inactive"}
                </span>
              </li>
              <li className="mt-3">
                <button onClick={handleLogout} className="btn theme_btn">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal */}
      <div
        className="modal fade"
        id="editModal"
        tabIndex="-1"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="sec_heading-stylish mb-0" id="editModalLabel">
                Edit User Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    defaultValue={firstName}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    defaultValue={lastName}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    defaultValue={userDetails.email}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    defaultValue={userDetails.phone || ""}
                  />
                </div>
                <button type="submit" className="btn theme_btn">
                  Save Changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSidebar;
