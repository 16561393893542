import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const About = () => {
    const [aboutData, setAboutData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const BASE_URL = process.env.REACT_APP_APP_URL;
    const API_URL = `${BASE_URL}/api/v1/topic/189`;

    useEffect(() => {
        AOS.init();

        const fetchAboutData = async () => {
            try {
                const response = await axios.get(API_URL);
                setAboutData(response.data);
            } catch (err) {
                setError("Error fetching data");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAboutData();
    }, [API_URL]);

    if (loading) {
        return (
          <section className="inner-banner-section skeleton-container">
            <div className="container">
                <div className="row">
                    <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-caption">
                            <div>
                              {/* eslint-disable-next-line */}
                                <h5 className="text-banner-badge hero skeleton"></h5>
                                  {/* eslint-disable-next-line */}
                                <h1 className="main-heading hero mb-0 skeleton"></h1>
                            </div>
                            <p className="pera hero skeleton"></p>
                            <div className="d-flex gap-3 justify-content-between">
                                <div className="btn-skeleton hero skeleton"></div>
                                <div className="hero-btn-container d-flex gap-1">
                                    <span className="play-skeleton hero skeleton"></span> 
                                    <p className="skeleton hero btn-skeleton"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                        <div className="banner-img">
                            <div className="video hero skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
        );
    }

    if (error) {
        return (
            <section className="sec_padding about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    // Extract the fields from the API response
    const photoFile = aboutData?.topic[0]?.fields?.find(field => field.type === 8)?.value || null;
    const paragraphOne = aboutData?.topic[0]?.fields?.find(field => field.title === "Paragraph_One")?.value || "";
    const paragraphTwo = aboutData?.topic[0]?.fields?.find(field => field.title === "Paragraph_two")?.value || "";

    return (
        <section className="sec_padding about-section">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        {/* Image Section */}
                        {photoFile && (
                            <div className="about-img" data-aos="zoom-in-right">
                                <img 
                                  src={`${BASE_URL}/uploads/topics/${photoFile}`} 
                                  alt="Michele" 
                                  onError={(e) => e.target.src = "/assets/images/default-image.png"} // Fallback image
                                />
                            </div>
                        )}
                    </div>
                    <div data-aos="zoom-in-left" className="col-xl-8 col-lg-8 col-md-6">
                        {/* Title */}
                        <h2 className="sec_heading">
                            Meet <span className="sec_heading-stylish">Michele</span>
                        </h2>
                        {/* Paragraph One */}
                        {paragraphOne && (
                            <div className="pera" dangerouslySetInnerHTML={{ __html: paragraphOne }} />
                        )}
                    </div>
                    <div className="col-md-12" data-aos="zoom-in-up">
                        {/* Paragraph Two */}
                        {paragraphTwo && (
                            <div className="pera" dangerouslySetInnerHTML={{ __html: paragraphTwo }} />
                        )}
                        {/* Link to My Story */}
                        <div className="text-center">
                            <Link to="/about" className="btn theme_btn mt-3">My story</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
