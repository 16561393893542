import React, { useEffect, useState } from "react";
import axios from "axios";
import AOS from "aos";

const Transformation = () => {
  const [data, setData] = useState(null);
  const baseUrl = process.env.REACT_APP_APP_URL; // Using the base URL from the environment variable

  useEffect(() => {
    AOS.init({
      duration: 1200,
    }); // Initialize AOS animations

    // Fetch data from the API using the base URL
    axios
      .get(`${baseUrl}/api/v1/topic/181`)
      .then((response) => {
        // Access the topic array and the fields within it
        setData(response.data.topic[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [baseUrl]);

  if (!data) {
    return (
      <section className="inner-banner-section skeleton-container">
        <div className="container">
          <div className="row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <div>
                  <h5 className="text-banner-badge hero skeleton"> </h5>
                  <h1 className="main-heading hero mb-0 skeleton"> </h1>
                </div>
                <p className="pera hero skeleton"></p>
                <div className="d-flex gap-3 justify-content-between">
                  <div className="btn-skeleton hero skeleton"></div>
                  <div className="hero-btn-container d-flex gap-1">
                    <span className="play-skeleton hero skeleton"></span>{" "}
                    <p className="skeleton hero btn-skeleton"></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <div className="video hero skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Map fields based on their title values to match component structure
  const titleOne = data.fields.find((field) => field.title === "title-one");
  const contentOne = data.fields.find((field) => field.title === "content-one");
  const titleTwo = data.fields.find((field) => field.title === "title-two");
  const contentTwo = data.fields.find((field) => field.title === "content-two");
  const titleThree = data.fields.find((field) => field.title === "title-three");
  const contentThree = data.fields.find((field) => field.title === "content-three");
  const titleFour = data.fields.find((field) => field.title === "title-four");
  const contentFour = data.fields.find((field) => field.title === "content-four");

  // Find images by their title for corresponding sections
  const imageOne = data.fields.find((field) => field.title === "image-one")?.value;
  const imageThree = data.fields.find((field) => field.title === "image-three")?.value;
  const imageFour = data.fields.find((field) => field.title === "image-four")?.value;
  const introContent = data.fields.find((field) => field.title === "Transformational Wellness!");

  return (
    <section className="sec_padding transformation">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-md-4 mb-3" data-aos="fade-right">
            <h2 className="sec_heading-stylish text-center">{data.title}</h2>
            <p className="title-pera-center">{introContent?.value}</p>
          </div>
        </div>
        <div className="row tr-items">
          <img src="assets/images/background-tr.webp" className="tr-img-bg" alt="" />
          <div className="col-md-4" data-aos="zoom-in-right">
            <div className="row">
              <div className="col-md-12 col-sm-6">
                <div className="tr-box left">
                  <div className="icon">
                    <img src={`assets/images/${baseUrl/imageOne || "Lighten-the-Load.png"}`} alt="" />
                  </div>
                  <h4 className="title-tr">{titleOne?.value}</h4>
                  <p className="pera">{contentOne?.value}</p>
                </div>
              </div>
              <div className="col-md-12 col-sm-6">
                <div className="tr-box left">
                  <div className="icon">
                    <img src={`./assets/images/${baseUrl/imageThree || "making-changes.png"}`} alt="" />
                  </div>
                  <h4 className="title-tr">{titleThree?.value}</h4>
                  <p className="pera">{contentThree?.value}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="img-tr" data-aos="zoom-in">
              <img src="./assets/images/wellness.png" alt="" />
            </div>
          </div>
          <div className="col-md-4" data-aos="zoom-in-left">
            <div className="row">
              <div className="col-md-12 col-sm-6">
                <div className="tr-box right">
                  <div className="icon">
                    <img src={`./assets/images/${baseUrl/imageOne || "Find-new-ways.png"}`} alt="" />
                  </div>
                  <h4 className="title-tr">{titleTwo?.value}</h4>
                  <p className="pera">{contentTwo?.value}</p>
                </div>
              </div>
              <div className="col-md-12 col-sm-6">
                <div className="tr-box right">
                  <div className="icon">
                    <img src={`./assets/images/${baseUrl/imageFour || "choice-yours.png"}`} alt="" />
                  </div>
                  <h4 className="title-tr">{titleFour?.value}</h4>
                  <p className="pera">{contentFour?.value}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Transformation;
