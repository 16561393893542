import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AOS from 'aos';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL;

  const API_URL = `${BASE_URL}/api/v1/topics/7/page/1/count/10`;

  useEffect(() => {
    AOS.init({ duration: 1200 });

    const fetchBlogs = async () => {
      try {
        const response = await axios.get(API_URL);
        // Limit to first 3 blogs
        setBlogs(response.data.topics.slice(0, 3));
      } catch (err) {
        setError("Error fetching blogs");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [API_URL]);

  if (loading) {
    return (
        
      <section className="inner-banner-section skeleton-container">
      <div className="container">
          <div className="row">
              <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                  <div className="banner-caption">
                      <div>
                          <h5 className="text-banner-badge hero skeleton"> </h5>
                          <h1 className="main-heading hero mb-0 skeleton"> </h1>
                      </div>
                      <div className="pera hero skeleton"> {/* Change from <p> to <div> */}
                          {/* Skeleton content here */}
                      </div>
                      <div className="d-flex gap-3 justify-content-between">
                          <div className="btn-skeleton hero skeleton"></div>
                          <div className="hero-btn-container d-flex gap-1">
                              <span className="play-skeleton hero skeleton"></span> 
                              <p className="skeleton hero btn-skeleton"></p>
                          </div>
                      </div>
                  </div>
              </div>
              <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                  <div className="banner-img">
                      <div className="video hero skeleton"></div>
                  </div>
              </div>
          </div>
      </div>
  </section>
        
    );
}

  if (error) return <p>{error}</p>;

  return (
    <section className="sec_padding articals">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-md-4 mb-1" data-aos="fade-right">
            {/* <h6 className="sub_heading text-center">Blogs</h6>
            <h2 className="sec_heading-stylish text-center">Together We Will</h2> */}
          </div>
          {/* Limit blogs to 3 */}
          {blogs.slice(0, 3).map((blog) => (
            <div className="col-md-4 mt-2" data-aos="fade-up" key={blog.id}>
              <Link to={`/blog-detail-page/${blog.id}`} className="artical-box">
                <div className="img-artical">
                  <img
                    src={blog.photo_file }
                    alt={blog.title}
                  />
                </div>
                <div className="content-artical">
                  <span className="detail">
                    by {blog.user.name} | {blog.date} |{" "}
                    {blog.Joined_categories[0]?.title || "Uncategorized"}
                  </span>
                  <h3 className="title-artical">{blog.title}</h3>
                  <p className="desc">
                    {blog.details
                      ? blog.details.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100) + "..."
                      : "No description available."}
                  </p>
                  <Link
                    to={`/blog-detail-page/${blog.id}`}
                    className="btn theme_btn mt-3"
                  >
                    Read More
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
