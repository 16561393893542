import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom"; 
import axios from "axios";
import AOS from 'aos';

const Header = () => {
    const [logoUrl, setLogoUrl] = useState("");
    const BASE_URL = process.env.REACT_APP_APP_URL;
    const API_URL_PHOTO = `${BASE_URL}/api/v1/topic/photos/180`;

    useEffect(() => {
        AOS.init({ duration: 100 });
        const fetchLogo = async () => {
            try {
                const response = await axios.get(API_URL_PHOTO);
                if (response.data.photos && response.data.photos.length > 0) {
                    setLogoUrl(response.data.photos[0].url);
                }
            } catch (error) {
                console.error("Error fetching logo:", error);
            }
        };
        fetchLogo();
    }, [API_URL_PHOTO]);

    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Check if user is logged in by checking localStorage for token
    const isLoggedIn = localStorage.getItem("authToken");

    return (
        <header id="header" data-aos="fade-down" className={`header-main ${scrolled ? "bg-header" : ""}`}>
            <nav className="navbar navbar-expand-md">
                <div className="container">
                    <NavLink className="logo" to="/">
                        <img src={logoUrl || "./logo.png"} alt="logo" />
                    </NavLink>

                    <div className="d-flex align-items-center">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/about" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        About
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/service" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        Service
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/shop" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        Shop
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/podcast" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        Podcast
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/blog" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        Blog
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/contact" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                        Contact Us
                                    </NavLink>
                                </li>

                                {/* Conditionally render Login/Register button or User Profile link */}
                                {!isLoggedIn ? (
                                    <Link to="/login" className="btn theme_btn ms-0 ms-lg-4">Login / Register</Link>
                                ) : (
                                    <Link to="/UserProfile" className="user-profile-icon" title="Michele">
                                        <img src="/assets/images/Michele.png" alt=""/>
                                    </Link>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
