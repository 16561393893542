import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AOS from "aos";

const BlogComponent = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const BASE_URL = process.env.REACT_APP_APP_URL;

  // Update API URL based on the current page
  const API_URL = `${BASE_URL}/api/v1/topics/7/page/${currentPage}/count/4`;

  useEffect(() => {
    AOS.init({ duration: 1200 });

    const fetchBlogs = async () => {
      try {
        const response = await axios.get(API_URL);
        const blogData = response.data.topics || [];
        setBlogs(blogData);
        setTotalPages(response.data.totalPages || 1); // Assuming the API returns total pages
      } catch (err) {
        setError("Error fetching blogs");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [API_URL, currentPage]);

  if (loading) {
    return (
      <section className="inner-banner-section skeleton-container">
        <div className="container">
          <div className="row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <h5 className="text-banner-badge hero skeleton"></h5>
                <h1 className="main-heading hero mb-0 skeleton"></h1>
                <p className="pera hero skeleton"></p>
                <div className="d-flex gap-3 justify-content-between">
                  <div className="btn-skeleton hero skeleton"></div>
                  <div className="hero-btn-container d-flex gap-1">
                    <span className="play-skeleton hero skeleton"></span>
                    <p className="skeleton hero btn-skeleton"></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <div className="video hero skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (error) return <p>{error}</p>;

  // Generate pagination items
  const generatePaginationItems = () => {
    let items = [];
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Link key={i} to="#" onClick={() => setCurrentPage(i)}>
          <li className={`item ${i === currentPage ? "active" : ""}`}>{i}</li>
        </Link>
      );
    }
    return items;
  };

  return (
<>
          {/* Render blogs */}
          {blogs.map((blog, index) => (
            <div className="col-md-6 mt-2 " key={index} data-aos="zoom-in-up">
              <Link to="#" className="artical-box">
                <div className="img-artical">
                  <img src={blog.photo_file} alt={blog.title} />
                </div>
                <div className="content-artical">
                  <span className="detail">
                    by {blog.user?.name || "Unknown Author"} | {blog.date} |{" "}
                    {blog.Joined_categories[0]?.title || "Uncategorized"}
                  </span>
                  <h3 className="title-artical">{blog.title}</h3>
                  <p className="desc">
                    {blog.details
                      ? blog.details.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100) + "..."
                      : "No description available."}
                  </p>
                  <Link
                    to={`/blog-detail-page/${blog.id}`}
                    className="btn theme_btn mt-3"
                  >
                    Read More
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        

        {/* <div className="pagination ">
          <Link
            to="#"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className="icon disabled pre"
          >
            <i className="fa-solid fa-arrow-left"></i>
          </Link>

          <ul className="pagination-items">
            {generatePaginationItems()}
          </ul>

          <Link
            to="#"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            className="icon next"
          >
            <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </div> */}

        {/* Pagination Controls */}
       
   
</>
  );
};

export default BlogComponent;
