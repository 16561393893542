import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UserSidebar from "../components/UserSidebar";
import CurrentPlan from "../components/CurrentPlan";
import AddressForm from "../components/AddressForm";
import PasswordSecurity from "../components/PasswordSecurity";
import Notifications from "../components/Notifications";



const UserProfile = () => {
   // State to track the active tab
   const [activeTab, setActiveTab] = useState('plan');

   // Function to handle tab click
   const handleTabClick = (tab) => {
     setActiveTab(tab);
   };
   
  return (
    <>
      <Header />

      {/* Breadcrumbs */}
      <section className="breadcrumb" data-aos="fade-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/" className="active">
                    User Profile
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="user-profile sec_padding">
        <div className="container">
          <div className="row">
            {/* User Sidebar */}
            <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0 opacity-90">
            <UserSidebar />
            </div>

            <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1 opacity-90">
                  {/* User Pills */}
                <ul className="nav nav-user-profile gap-1 nav-pills flex-column flex-md-row mb-4">
                  <li className="nav-item">
                    <Link
                      to="#"
                      className={`nav-link ${activeTab === 'plan' ? 'active' : ''}`}
                      onClick={() => handleTabClick('plan')}
                    >
                      <i className="fas fa-layer-group me-1"></i>Plan
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#"
                      className={`nav-link ${activeTab === 'address' ? 'active' : ''}`}
                      onClick={() => handleTabClick('address')}
                    >
                      <i className="fas fa-address-card me-1"></i>Address
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#"
                      className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
                      onClick={() => handleTabClick('security')}
                    >
                      <i className="fas fa-lock me-1"></i>Security
                    </Link>
                  </li>
                  {/* New Notifications Tab */}
                  <li className="nav-item">
                    <Link
                      to="#"
                      className={`nav-link ${activeTab === 'notifications' ? 'active' : ''}`}
                      onClick={() => handleTabClick('notifications')}
                    >
                      <i className="fas fa-bell me-1"></i>Notifications
                    </Link>
                  </li>
                </ul>
                {/* /User Pills */}

                {/* Tab Content */}
                <div className="tab-content">
                  {activeTab === 'plan' && (
                  
                  <CurrentPlan/>
                
                  )}
                  {activeTab === 'address' && (
                  <AddressForm/>
                
                  )}
                  {activeTab === 'security' && (
                <PasswordSecurity />
                )}
                {/* New Notifications Tab Content */}
                {activeTab === 'notifications' && (

                <Notifications />   
              
                )}
              </div>
              {/* /Tab Content */}
            </div>        
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default UserProfile;
