import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogSidebar from "../components/BlogSidebar";
import AOS from 'aos';
import SocialLinks from "../components/SocialLinks";

const BlogDetail = () => {
  const [blogData, setBlogData] = useState(null);
  const { id } = useParams(); // Get the blog ID from the URL

  useEffect(() => {
    AOS.init({
      duration: 1200,
      
  });


    const fetchData = async () => {
      const BASE_URL = process.env.REACT_APP_APP_URL;
      const API_URL = `${BASE_URL}/api/v1/topic/${id}`; // Use the dynamic ID in the URL
      try {
        const response = await fetch(API_URL);
        const result = await response.json();
        if (result && result.topic && result.topic[0]) {
          setBlogData(result.topic[0]);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!blogData) {
        return (
            <p>
                <section className="inner-banner-section skeleton-container">
                    <div className="container">
                        <div className="row">
                            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
                                <div className="banner-caption">
                                    <div>
                                        <h5 className="text-banner-badge hero skeleton"> </h5>
                                        <h1 className="main-heading hero mb-0 skeleton"> </h1>
                                    </div>
                                    <p className="pera hero skeleton">
                                    </p>
                                    <div className="d-flex gap-3 justify-content-between">
                                        <div className="btn-skeleton hero skeleton"></div>
                                        <div className="hero-btn-container d-flex gap-1">
                                            <span className="play-skeleton hero skeleton"></span> <p className="skeleton hero btn-skeleton"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
                                <div className="banner-img">
                                    <div className="video hero skeleton"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </p>
        );
    }


  return (
    <>
      <Header />

      {/* ---Breadcrumb--- */}
      <section className="breadcrumb" data-aos="fade-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="items">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/"><i className="fa fa-angle-right"></i></Link></li>
                <li><Link to="/" >Blogs</Link></li>
                <li><Link to="/"><i className="fa fa-angle-right"></i></Link></li>
                <li><Link to="/" className="active">{blogData.title}</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* ---Single Blog Detail--- */}
      <section className="blog-detail-top sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-md-4" data-aos="zoom-in-up">
              <h2 className="sec_heading-stylish text-center">{blogData.title}</h2>
              <p className="details text-center">
                by {blogData.user.name} | {new Date(blogData.date).toLocaleDateString()} | {blogData.Joined_categories[0].title}
              </p>
             <ul className="social">
              <SocialLinks/>
             </ul>
            </div>
            <div className="col-md-12" data-aos="zoom-in-up">
              <div className="blog-feature-img">
                <img src={blogData.photo_file} alt={blogData.title} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ---Blog Content--- */}
      <section className="blog-detail-container sec_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="blog-detail-content" data-aos="fade-up">
                <div dangerouslySetInnerHTML={{ __html: blogData.details }}></div>
                <ol>
                  {blogData.fields.map((field, index) => (
                    <li key={index}>
                      <h6>{field.title}</h6>
                      <p>{field.value}</p>
                    </li>
                  ))}
                </ol>
              </div>

              {/* ---comment box--- */}

              <div className="sec_padding">
  <div className="comment-box">
    <div className="block-header">
      <h2 className="sec_heading mb-0">
        Comments 
        <span className="tag"> (12)</span>
      </h2>
      <div className="group-radio">
        <span className="button-radio">
          <input id="latest" name="latest" type="radio" defaultChecked="" />
          <label htmlFor="latest">Latest</label>
        </span>
        <div className="divider" />
        <span className="button-radio">
          <input id="popular" name="latest" type="radio" />
          <label htmlFor="popular">Popular</label>
        </span>
      </div>
    </div>
    <div className="writing">
      <div
        contentEditable="true"
        className="textarea"
        autoFocus=""
        spellCheck="false"
      >
        <p>Hi</p>
      </div>
      <div className="footer-comment">
        {/* <div className="text-format">
          <button className="btn">
            <i className="fa fa-bold" />
          </button>
          <button className="btn">
            <i className="fa fa-italic" />
          </button>
          <button className="btn">
            <i className="fa fa-underline" />
          </button>
          <button className="btn">
            <i className="fa fa-list-ul" />
          </button>
        </div> */}
        <div className="group-button">
          {/* <button className="btn">
            <i className="fa fa-at" />
          </button> */}
          <button className="btn theme_btn">Send</button>
        </div>
      </div>
    </div>

    {/* --items-- */}
      <div className="single-comment-box">
        <div className="comment">
          <div className="user-banner">
            <div className="user">
              <div className="avatar">
                <img src="/assets/images/user/user-one.png" alt="" />
                <span className="stat grey" />
              </div>
              <h5 className="user-name">Floyd Miles</h5>
            </div>
            <div class="dropdown edit-dropdown">
              <button class="btn dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-ellipsis-v" />
              </button>
              <ul class="dropdown-menu dropdown-content">
                <li><Link class="dropdown-item" to="#">Edit</Link></li>
                <li><Link class="dropdown-item" to="#">Delete</Link></li>
              </ul>
            </div>
          </div>
          <div className="content">
            <p>
              Actually, now that I try out the links on my message, above, none of
              them take me to the secure site. Only my shortcut on my desktop, which I
              created years ago.
            </p>
          </div>
          <div className="footer-comment">
            <Link to="#">Reply</Link>
            <div className="divider" />
            <span className="is-mute">6 hour ago</span>
            <div className="divider" />
            <Link to="#" className="view-more">View more</Link>
          </div>
        </div>        
      </div>
    {/* --items-- */}
      <div className="single-comment-box">
        <div className="comment">
          <div className="user-banner">
            <div className="user">
              <div className="avatar">
                <img src="/assets/images/user/user-two.png" alt="" />
                <span className="stat grey" />
              </div>
              <h5 className="user-name">Bessie Cooper</h5>
            </div>
            <div class="dropdown edit-dropdown">
              <button class="btn dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-ellipsis-v" />
              </button>
              <ul class="dropdown-menu dropdown-content">
                <li><Link class="dropdown-item" to="#">Edit</Link></li>
                <li><Link class="dropdown-item" to="#">Delete</Link></li>
              </ul>
            </div>
          </div>
          <div className="content">
            <p>
              Actually, now that I try out the links on my message, above, none of
              them take me to the secure site. Only my shortcut on my desktop, which I
              created years ago.
            </p>
          </div>
          <div className="footer-comment">
            <Link to="#">Reply</Link>
            <div className="divider" />
            <span className="is-mute">6 hour ago</span>
            <div className="divider" />
            <Link to="#" className="view-more">View more</Link>
          </div>
        </div>
        <div className="writing mt-1">
          <div
            contentEditable="true"
            className="textarea"
            autoFocus=""
            spellCheck="false"
          >
            <p>Hi</p>
          </div>
          <div className="footer-comment">
            {/* <div className="text-format">
              <button className="btn">
                <i className="fa fa-bold" />
              </button>
              <button className="btn">
                <i className="fa fa-italic" />
              </button>
              <button className="btn">
                <i className="fa fa-underline" />
              </button>
              <button className="btn">
                <i className="fa fa-list-ul" />
              </button>
            </div> */}
            <div className="group-button">
              {/* <button className="btn">
                <i className="fa fa-at" />
              </button> */}
              <button className="btn theme_btn">Send</button>
            </div>
          </div>
        </div>
        <div className="reply comment">
            <div className="user-banner">
              <div className="user">
                <div className="avatar">
                  <img
                    src="/assets/images/user/user-one.png"
                    alt=""
                  />
                  <span className="stat green" />
                </div>
                <h5 className="user-name">Albert Flores</h5>
              </div>
              <div class="dropdown edit-dropdown">
              <button class="btn dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-ellipsis-v" />
              </button>
              <ul class="dropdown-menu dropdown-content">
                <li><Link class="dropdown-item" to="#">Edit</Link></li>
                <li><Link class="dropdown-item" to="#">Delete</Link></li>
              </ul>
            </div>
            </div>
            <div className="content">
              <p>
                Hi{" "}
                <Link to="#" className="tagged-user">
                @Bessie Cooper
                </Link>
                . Thanks for your reply.
              </p>
            </div>
            <div className="footer-comment">
              <Link to="#">Reply</Link>
              <div className="divider" />
              <span className="is-mute">18 sec</span>
            </div>
        </div>
      </div>
  </div>
</div>


            </div>
            <div className="col-md-3 mt-3">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </section>

      {/* ---Related Blogs--- */}
      <Footer />
    </>
  );
};

export default BlogDetail;
