import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ServiceDetail from "../components/ServiceDetail";
import { Link } from "react-router-dom";
import Testimonials from "../components/Testimonials";
import AOS from "aos";

const ServiceDetailPage = () => {
    // Initialize AOS and scroll to top when the component mounts
    useEffect(() => {
        AOS.init({
            duration: 1200, // Animation duration
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />

            {/* ---breadcrumb--start---- */}
            <section className="breadcrumb" data-aos="fade-right">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">Service</Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i className="fa fa-angle-right"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">
                                        Personal Training & 7 Minute Miracles
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* ---breadcrumb--end---- */}

            <ServiceDetail />
            <Testimonials />
            <Footer />
        </>
    );
};

export default ServiceDetailPage;
