import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import axios from "axios";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = `${BASE_URL}/api/v1/topics/2/page/1/count/10`;

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations
    const fetchServices = async () => {
      try {
        const response = await axios.get(API_URL);
        // Skip the first two services and take the next four
        const filteredServices = response.data.topics.slice(2, 6).map((service) => {
          const { subtitle, cleanDetails } = extractSubtitleAndClean(service.details);
          return {
            ...service,
            subtitle,
            details: cleanDetails,
          };
        });
        setServices(filteredServices);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [API_URL]);


  const extractSubtitleAndClean = (details) => {
    const h4Regex = /<h4[^>]*>(.*?)<\/h4>/;
    const match = details.match(h4Regex);


    const subtitle = match ? match[1] : "No subtitle available";

    // Remove the <h4> tag and its content from the details
    const cleanDetails = details.replace(h4Regex, "").trim();

    return { subtitle, cleanDetails };
  };

 
  const truncateText = (text, maxChars = 300) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars) + "..."; // Truncate and add ellipsis
    }
    return text;
  };

  
  const formatTitle = (title) => {
    const words = title.split(" ");

    if (words.length === 2) {
   
      words[1] = `<span class="stylish">${words[1]}</span>`;
    } else if (words.length === 3) {
     
      words[2] = `<span class="stylish">${words[2]}</span>`;
    } else if (words.length === 4) {
     
      words[2] = `<span class="stylish">${words[2]}</span>`;
      words[3] = `<span class="stylish">${words[3]}</span>`;
    }

    return words.join(" ");
  };

  if (loading) {
    return (
      <section className="inner-banner-section skeleton-container">
        <div className="container">
          <div className="row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <div>
                  <h5 className="text-banner-badge hero skeleton"> </h5>
                  <h1 className="main-heading hero mb-0 skeleton"> </h1>
                </div>
                <p className="pera hero skeleton"> </p>
                <div className="d-flex gap-3 justify-content-between">
                  <div className="btn-skeleton hero skeleton"></div>
                  <div className="hero-btn-container d-flex gap-1">
                    <span className="play-skeleton hero skeleton"></span>
                    <p className="skeleton hero btn-skeleton"></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <div className="video hero skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (error) return <p>{error}</p>;

  // Gradient classes
  const gradientClasses = ["gradient-one", "gradient-two", "gradient-three", "gradient-four"];

  return (
    <section className="sec_padding our-services">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-md-4 mb-3" data-aos="fade-right">
            <h6 className="sub_heading text-center">Services</h6>
            <h2 className="sec_heading-stylish text-center">Our Offerings</h2>
            <span className="down-arrow">
              <i className="fa-solid fa-arrow-down-long"></i>
            </span>
          </div>

          {/* Loop through services and render each one */}
          {services.map((service, index) => (
            <div key={service.id} className="col-md-6" data-aos="fade-up">
              <div className="padding-top-item">
                <div className="service-item text-center">
                  <div className={`box-details ${gradientClasses[index]}`}>
                    <h5 className="Presents">
                      <span className="stylish">Michele </span>Presents
                    </h5>
                    {/* Render dynamic title with words in <span className="stylish"> */}
                    <h3 className="title" dangerouslySetInnerHTML={{ __html: formatTitle(service.title) }}></h3>
                    <h4 className="sub-title">{service.subtitle}</h4>
                  </div>
                  {/* Truncate details to a max of 300 characters */}
                  <p className="pera">
                    {truncateText(service.details, 300)} {/* Adjust 300 as needed */}
                  </p>
                  <Link
                    to={`/service-detail/${service.id}`}
                    className="btn theme_btn"
                  >
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
