import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import axios from "axios";
import "aos/dist/aos.css";

const BlogSidebar = () => {
  const [blogs, setBlogs] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const BASE_URL = process.env.REACT_APP_APP_URL;

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    // Fetch the blogs data
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/topics/7/page/1/count/10`
        );
        const data = await response.json();

        // Extract the first three blog posts
        if (data.topics) {
          setBlogs(data.topics.slice(0, 3));
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/topic/217`);
        const fields = response.data.topic[0]?.fields;

        if (fields) {
          setSocialLinks(fields);
        }
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchSocialLinks();
  }, [BASE_URL]);

  // Filter out only WhatsApp, Instagram, and Facebook
  const filteredLinks = socialLinks.filter(
    (link) => ["Whatsapp", "Instagram", "Facebook"].includes(link.title)
  );

  return (
    <div className="blog-sidebar">
      <div className="searchbar" data-aos="fade-up">
        <input type="search" className="search-input" placeholder="Search" />
        <div className="icon-submit">
          <i className="fas fa-search"></i>
        </div>
      </div>

      <div className="about-feed feed" data-aos="fade-up">
        <h3 className="heading">About</h3>
        <div className="info">
          <div className="img">
            <img src="assets/images/Michele.png" alt="Michele" />
          </div>
          <div className="detail">
            <h4 className="name">Michele</h4>
            <p className="desination">Fitness Professional</p>
          </div>
        </div>
        <p className="content">
          Welcome! I’m Michele Drake, an alternative health, wellness, and
          fitness professional with over 35 years of experience.
        </p>
        <ul className="social">
          <li>
            <Link to="#">
              <i className="fa-solid fa-location-dot"></i>
            </Link>
            <span>Canada</span>
          </li>

          {/* Rendering filtered social links */}
          {filteredLinks.map((link, index) => {
            let iconClass = `fa-brands fa-${link.title.toLowerCase()}`;
            return (
              <li key={index}>
                <Link to={link.value} target="_blank" rel="noopener noreferrer" >
                  <i className={iconClass}></i>
                </Link>
              </li>
            );
          })}
          
        </ul>
      </div>

      <div className="category feed" data-aos="fade-up">
        <h3 className="heading">Category</h3>
        <ul className="categoty-list">
          <li>
            <Link to="#">Fitness</Link>
          </li>
          <li>
            <Link to="#">Health</Link>
          </li>
          <li>
            <Link to="#">Wellness</Link>
          </li>
          <li>
            <Link to="#">Workout</Link>
          </li>
        </ul>
      </div>

      <div className="Popular-post feed" data-aos="fade-up">
        <h3 className="heading">Popular Post</h3>
        <div className="PopularPost-list">
          {blogs.map((blog) => (
            <Link key={blog.id} to={`/blog/${blog.id}`} className="item">
              <div className="img">
                <img src={blog.photo_file} alt={blog.title} loading="lazy" />
              </div>
              <p className="content">{blog.title}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
