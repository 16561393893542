import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import AOS from 'aos';

const Error404 = () => {
    AOS.init(
        {
            duration: 1200
          }
    ); // Initialize AOS animations
    return (
        <>
          <Header />
          
             {/* ---bradrumb--start----  */}

             <section className="breadcrumb" data-aos="fade-right">

                <div className="container">

                    <div className="row">

                        <div className="col-12">

                            <ul className="items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/"><i className="fa fa-angle-right"></i></Link>
                                </li>
                                <li>
                                    <Link to="/" className="active">Page Not found</Link>
                                </li>
                            </ul>
                        
                        </div>

                    </div>

                </div>

            </section>

            {/* ---page-not-found---  */}

            <section className="sec_padding page-not-found d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h1 className="error text-center">404</h1>
                        <div className="page text-center">Ooops!!! The page you are looking for is not found</div>
                       <div className="text-center">
                        <Link className="btn theme_btn" to="/">Back to home</Link>
                       </div>
                        </div>
                    </div>
                </div>
            </section>

        
            
        </>
    );
};

export default Error404;
