import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';

const LightentheRoad = () => {
  const [data, setData] = useState(null); // Existing API data
  const [videoData, setVideoData] = useState(null); // Video API data

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    const fetchData = async () => {
      const BASE_URL = process.env.REACT_APP_APP_URL;
      const MAIN_API_URL = `${BASE_URL}/api/v1/topic/187`; // Main API URL
      const VIDEO_API_URL = `${BASE_URL}/api/v1/topic/182`; // Video API URL

      try {
        // Fetch main API data
        const mainResponse = await fetch(MAIN_API_URL);
        const mainResult = await mainResponse.json();
        if (mainResult && mainResult.topic && mainResult.topic[0]) {
          setData(mainResult.topic[0]);
        }

        // Fetch video API data
        const videoResponse = await fetch(VIDEO_API_URL);
        const videoResult = await videoResponse.json();
        if (videoResult && videoResult.topic && videoResult.topic[0]) {
          setVideoData(videoResult.topic[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Skeleton loader while data is loading
  if (!data || !videoData) {
    return (
      <section className="inner-banner-section skeleton-container">
        <div className="container">
          <div className="row">
            <div data-aos="zoom-in-right" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-caption">
                <h5 className="text-banner-badge hero skeleton"></h5>
                <h1 className="main-heading hero mb-0 skeleton"></h1>
                <p className="pera hero skeleton"></p>
                <div className="d-flex gap-3 justify-content-between">
                  <div className="btn-skeleton hero skeleton"></div>
                  <div className="hero-btn-container d-flex gap-1">
                    <span className="play-skeleton hero skeleton"></span>
                    <p className="skeleton hero btn-skeleton"></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-left" className="col-xl-6 col-lg-6 col-md-12">
              <div className="banner-img">
                <div className="video hero skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="container">
      <div className="row">
        {/* Main API Content */}
        <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-right">
          <div className="banner-caption">
            <div>
              <h5 className="text-banner-badge">Services</h5>
              <h1 className="main-heading mb-0">{data.title}</h1>
            </div>
            {data.details && (
              <div
                className="pera"
                dangerouslySetInnerHTML={{ __html: data.details }} // Render details if available
              />
            )}
            <div className="d-flex gap-3 justify-content-between">
              <Link to="https://masterhabits.com/" className="btn theme_btn">
                Take a Quiz Now
              </Link>
              <Link to="/podcast" className="podcast-btn">
                <div className="waves wave-1"></div>
                <div className="waves wave-2"></div>
                <div className="waves wave-3"></div>
                <span>
                  <i className="fa fa-play"></i>
                </span>
                <p className="podcast-text-none">Free Podcast</p>
              </Link>
            </div>
          </div>
        </div>

        {/* Video Section */}
        <div className="col-xl-6 col-lg-6 col-md-12" data-aos="zoom-in-left">
          <div className="banner-img">
            {videoData.video_file ? (
              <video controls src={videoData.video_file} />
            ) : (
              <p>No video available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightentheRoad;
