import React, { useState } from "react";

const AddressForm = () => {
  const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(false);

  const handleCheckboxChange = () => {
    setIsShippingSameAsBilling(!isShippingSameAsBilling);
  };

  return (
    <div className="row">
      <div className="col-12">
        {/* Billing Address Card */}
        <div className="card">
          <h5 className="card-header">Billing Address</h5>
          <div className="card-body">
            <form id="billingAddressForm">
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="name" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your full name"
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="street" className="form-label">
                    Street Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="street"
                    placeholder="Enter your street address"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    placeholder="Enter your city"
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    placeholder="Enter your state"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="zip" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    placeholder="Enter your zip code"
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    placeholder="Enter your country"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div>
          <div className="form-check my-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="sameAsBilling"
              checked={isShippingSameAsBilling}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="sameAsBilling">
              Shipping address is the same as billing address
            </label>
          </div>
        </div>

        {/* Shipping Address Card */}
        <div className="card">
          <h5 className="card-header">Shipping Address</h5>
          <div className="card-body">
            <form id="shippingAddressForm">
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shippingName" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shippingName"
                    placeholder="Enter your full name"
                    disabled={isShippingSameAsBilling}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shippingStreet" className="form-label">
                    Street Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shippingStreet"
                    placeholder="Enter your street address"
                    disabled={isShippingSameAsBilling}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shippingCity" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shippingCity"
                    placeholder="Enter your city"
                    disabled={isShippingSameAsBilling}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shippingState" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shippingState"
                    placeholder="Enter your state"
                    disabled={isShippingSameAsBilling}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shippingZip" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shippingZip"
                    placeholder="Enter your zip code"
                    disabled={isShippingSameAsBilling}
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label htmlFor="shippingCountry" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="shippingCountry"
                    placeholder="Enter your country"
                    disabled={isShippingSameAsBilling}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="text-end">
          <button type="submit" className="btn theme_btn mt-3">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
